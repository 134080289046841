<template>
  <v-container class="px-5 mb-10">
    <v-row class="tr-outlined rounded-lg">
      <v-col class="pa-10" cols="12" md="6">
        <div class="text-overline font-weight-bold">Wallet Balance</div>
        <div class="primary--text">
          <sup class="text-h6">&#x24;</sup>
          <span v-if="wallet === ''" class="text-h3">********</span
          ><span v-else class="text-h3">{{ wallet || 0 | formatPrice }}</span>
          <div class="text-caption mt-2">
            Wallet ID:
            <span class="font-weight-bold secondary--text">{{
              account_number ? account_number : ""
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col class="pa-10" cols="12" md="6">
        <div class="text-h6 primary--text font-width-normal">
          Transfer, Pay &amp; Receive money internationally
        </div>
        <div class="mt-3">
          Click here to copy payment link and recieve payment from anywhere
        </div>
        <v-btn
          class="mt-5"
          @click="paymentlink = false"
          color="secondary"
          dark
          outlined
          depressed
          large
          >Payment Link</v-btn
        >
      </v-col>
      <v-col class="text-center pa-10 top-outline" cols="12">
        <fund-transfer></fund-transfer>
      </v-col>
    </v-row>

    <v-row class="mt-15">
      <v-col cols="12" md="4" class="pa-0">
        <v-card class="rounded-lg" style="background-color: aliceblue" outlined>
          <div class="pa-7">
            <div class="primary--text">Dollar Exchange Rate</div>
            <div class="primary--text mt-5">
              <span class="text-h4 font-weight-medium">&#x24;1 = &#8358;{{ usd_ngn | formatPrice }}</span>
            </div>
            <div class="text-caption mt-5 primary--text">
              Note: You will be charged additional amount when sending money abroad
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="pa-0 px-md-5 pt-5 pt-md-0">
        <new-card></new-card>
      </v-col>
    </v-row>
    <v-row class="mt-15">
      <v-col cols="12" class="pa-0">
        <v-card outlined class="rounded-lg">
          <v-toolbar flat class="mt-5 pl-5">
            <v-toolbar-title class="text-h5 font-weight-medium primary--text mb-4"
              >Transactions</v-toolbar-title
            >
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="transactions"
            :loading="loading"
            class="myTable"
            loading-text="Loading data... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="paymentlink" width="500">
      <v-card class="pa-6">
        <div class="">
          <v-toolbar flat>
            <v-toolbar-title class="mb-2 primary--text text-h5 font-weight-medium"
              >Payment Link</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              fab
              small
              color="gray lighten-5"
              depressed
              @click="paymentlink = !paymentlink"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <div class="text-body-2 text--secondary px-4">
            Recieve money into your dollar wallet when you send your friends the payment
            link below
          </div>
        </div>
        <v-alert prominent class="indigo lighten-5 mt-6 mx-4 tr-outlined">
          <v-row align="center">
            <v-col class="grow"> </v-col>
            <v-col class="shrink">
              <v-icon color="blue lighten-2">mdi-content-copy</v-icon>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FundTransfer from "@/components/remittance/transact-action";
import NewCard from "@/components/remittance/newcard";

export default {
  data() {
    return {
      wallet: "",
      account_number: "",
      selected: "",
      headers: [
        { text: "Transaction", align: "start", value: "" },
        { text: "Type", value: "" },
        { text: "Recipient", value: "" },
        { text: "Date", value: "" },
      ],
      transactions: [],
      loading: false,
      dialog: false,
      paymentlink: false,
      transfermoney: false,
      banks: [],
      cards: [],
      usd_ngn: process.env.VUE_APP_ENV_NGN_USD 
    };
  },
  components: {
    FundTransfer,
    NewCard,
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("remittance", ["getcards"]),

    async getUSDBal() {
      this.wallet = 0;
    },

    async getCards() {
      const data = {
        id: this.user.id,
      };
      try {
        let response = await this.getcards(data);
        this.cards = response.data;
      } catch (error) {}
    },
  },

  mounted() {
    this.getUSDBal(), this.getCards();
  },
};
</script>

<style scoped>
.tr-outlined {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.top-outline {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
thead.v-data-table-header {
  background-color: aliceblue !important;
}
</style>
