<template>
   <div>
      <v-btn class="mx-md-5" color="primary" outlined depressed large @click="fundwallet = true">Fund Wallet</v-btn>
      <v-btn class="primary mx-5" depressed large @click="transfermoney = true">Transfer Money</v-btn>
      <v-btn class="mx-md-5" color="secondary px-10 mt-md-0 mt-3" depressed large @click="showpaybills = true">Pay Bills</v-btn>

      <v-dialog v-model="fundwallet" width="600">
        <v-card class="pa-3 pa-md-6 py-6">
            <div class="">
               <v-toolbar flat>
                  <v-toolbar-title class="mb-2 primary--text text-h5 font-weight-medium">Fund Wallet</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="fundwallet = !fundwallet"><v-icon>mdi-close</v-icon></v-btn>
               </v-toolbar>
               <div class="text-body-2 text--secondary px-4"> Recieve money into your dollar wallet when you send your friends the payment link below</div>
            </div>
            <v-form class="mx-4 mt-5" ref="fund">
               <div class="text-subtitle-1 primary--text mb-1 font-weight-medium">Select funding method</div>
               <v-select outlined v-model="form.method" 
                  :items="methods" required :rules="[rules.required]">
               </v-select>
               
               <div v-if="form.method == 'wallet'">
                  <div class="text-subtitle-1 primary--text font-weight-medium">Enter Amount</div>
                  <v-row>
                     <v-col cols="6" class="">
                        <v-text-field outlined v-model="form.currency_ngn" @input="convert_currency()" class="tr--country" required :rules="[rules.required]">
                           <template v-slot:append>
                              <country-flag country='ng' size='normal'/>NGN
                           </template>
                        </v-text-field>
                     </v-col>
                     <span class="tr--icon" style="cursor: pointer">
                        <div class="elevation-1 rounded-pill pa-3" style="background-color: white; opacity: 0.9">
                           <v-icon color="secondary">mdi-arrow-left-right-bold-outline</v-icon>
                        </div>
                     </span>
                     <v-col cols="6" class="">
                        <v-text-field outlined v-model="form.currency_usd" readonly class="tr--country" required :rules="[rules.required]">
                           <template v-slot:append>
                              <country-flag country='us' size='normal'/>USD
                           </template>
                        </v-text-field>
                     </v-col>
                  </v-row>
                  <div class="text-subtitle-1 primary--text font-weight-medium">PIN</div>
                  <v-text-field outlined v-model="form.pin" autocomplete="new-password" type="password" required :rules="[rules.required]"></v-text-field>
               </div>

               <div v-if="form.method == 'card'">
                  <div class="text-subtitle-1 primary--text font-weight-medium">Card Number</div>
                  <v-text-field outlined v-model="form.card_number" required :rules="[rules.required]"></v-text-field>

                  <div class="text-subtitle-1 primary--text font-weight-medium">Enter Amount</div>
                  <v-row>
                     <v-col cols="6" class="">
                        <v-text-field outlined v-model="form.expirey" 
                        placeholder="00/00" required 
                        :rules="[rules.required]"></v-text-field>
                     </v-col>
                     <v-col cols="6" class="">
                        <v-text-field outlined v-model="form.cvv" 
                        placeholder="CVV" required 
                        :rules="[rules.required]"></v-text-field>
                     </v-col>
                  </v-row>
               </div>

               <div v-if="form.method == 'mpesa'">
                  <div class="text-subtitle-1 primary--text font-weight-medium">Enter Amount</div>
                  <v-text-field outlined v-model="form.amount" required :rules="[rules.required]"></v-text-field>

                  <div class="text-subtitle-1 primary--text font-weight-medium">Select Currency</div>
                  <v-select outlined v-model="form.currency" 
                  :items="currencies" :rules="[rules.required]">
                  </v-select>
               </div>

               <v-btn class="primary" depressed block :loading="loading" x-large @click="$refs.fund.validate() ? fund() : null" >Continue to Fund</v-btn>
            </v-form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="transfermoney" width="600">
         <v-card class="pa-5">
             <div class="">
               <v-toolbar flat>
                  <v-toolbar-title class="mb-2 primary--text text-h5 font-weight-medium">Transfer Money</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="transfermoney = !transfermoney"><v-icon>mdi-close</v-icon></v-btn>
               </v-toolbar>
               <div class="text-body-2 text--secondary px-4">Send money to to friends and family</div>
            </div>
            <v-form class="mx-4 mt-5" ref="fund">
               <div class="text-subtitle-1 primary--text font-weight-medium">Enter Amount</div>
               <v-row>
                  <v-col cols="6" class="">
                     <v-text-field outlined v-model="form.amount" type="number" @input="convert_currency()" class="tr--country" required :rules="[rules.required]">
                        <template v-slot:append>
                           <country-flag country='ng' size='normal'/>NGN
                        </template>
                     </v-text-field>
                  </v-col>
                  <span class="tr--icon-2" style="cursor: pointer">
                     <div class="elevation-1 rounded-pill pa-3" style="background-color: white; opacity: 0.9">
                        <v-icon color="secondary">mdi-arrow-left-right-bold-outline</v-icon>
                     </div>
                  </span>
                  <v-col cols="6" class="">
                     <v-text-field outlined v-model="form.currency" readonly class="tr--country" required :rules="[rules.required]">
                        <template v-slot:append>
                           <country-flag country='us' size='normal'/>USD
                        </template>
                     </v-text-field>
                  </v-col>
               </v-row>

               <div class="text-subtitle-1 primary--text mb-1 font-weight-medium">Select transfer destination</div>
               <v-select outlined v-model="form.destination" 
                  :items="destination" required :rules="[rules.required]">
               </v-select>

               <v-row v-if="form.destination == 'foreign'">
                  <v-col cols="6" class="pb-0">
                     <div class="text-subtitle-1 primary--text font-weight-medium">Country</div>
                     <v-select outlined v-model="form.country" @input="getbanks()"
                        :items="countries" required :rules="[rules.required]">
                     </v-select>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                     <div class="text-subtitle-1 primary--text font-weight-medium">Bank Name</div>
                     <v-select outlined eager v-model="form.bank_name" 
                        item-text="name" item-value="code"
                        :items="banks" return-object required :rules="[rules.required]">
                     </v-select>
                  </v-col>
                  <v-col cols="6" class="">
                     <div class="text-subtitle-1 primary--text font-weight-medium">Bank Account</div>
                     <v-text-field outlined v-model="form.account_number" 
                     required :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="">
                     <div class="text-subtitle-1 primary--text font-weight-medium">Account Name</div>
                     <v-text-field outlined v-model="form.account_name" 
                     required :rules="[rules.required]"></v-text-field>
                  </v-col>
               </v-row>
               <template  v-if="form.destination == 'wallet'">
                  <div class="text-subtitle-1 primary--text font-weight-medium">Wallet ID</div>
                  <v-text-field outlined v-model="form.wallet_id" required :rules="[rules.required]"></v-text-field>

                  <div class="text-subtitle-1 primary--text font-weight-medium">Account Name</div>
                  <v-text-field outlined v-model="form.account_name" readonly></v-text-field>
               </template>
               <div class="text-subtitle-1 primary--text font-weight-medium">Description (<i>Optional</i>)</div>
               <v-textarea outlined v-model="form.narration"></v-textarea>

               <div class="text-subtitle-1 primary--text font-weight-medium">PIN</div>
               <v-text-field outlined v-model="form.pin" type="password" autocomplete="new-password" :rules="[rules.required]"></v-text-field>

            <v-btn class="primary" depressed block :loading="loading" x-large @click="$refs.fund.validate() ? transfer() : null" >Continue to Transfer</v-btn>
         </v-form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showpaybills" width="500">
         <v-card class="pa-6">
             <div class="">
               <v-toolbar flat>
                  <v-toolbar-title class="mb-2 primary--text text-h5 font-weight-medium">Local and International bills</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="showpaybills = !showpaybills"><v-icon>mdi-close</v-icon></v-btn>
               </v-toolbar>
               <div class="text-body-2 text--secondary px-4">Pay your fees abroad in 2 - 5 business days</div>
            </div>
            <div class="container mt-5">
               <div class="card elevation-4 rounded-lg mb-5">
                  <v-list-item class="px-5 py-3" link>
                     <v-list-item-avatar>
                        <v-img :src="require('../../assets/icons/uk.png')" lazy-src=""></v-img>
                     </v-list-item-avatar>
                     <v-list-item-content>
                        <v-list-item-title class="primary--text font-weight-medium">Pay in UK</v-list-item-title>
                        <v-list-item-subtitle>Tuition, Application &amp; SEVIS Fees</v-list-item-subtitle>
                     </v-list-item-content>
                     <v-list-item-action>
                        <v-btn icon>
                           <v-icon color="primary">mdi-arrow-right</v-icon>
                        </v-btn>
                     </v-list-item-action>
                  </v-list-item>
               </div>
               <div class="card elevation-4 rounded-lg mb-5">
                  <v-list-item class="px-5 py-3" link>
                     <v-list-item-avatar>
                        <v-img :src="require('../../assets/icons/usa.png')" lazy-src=""></v-img>
                     </v-list-item-avatar>
                     <v-list-item-content>
                        <v-list-item-title class="primary--text font-weight-medium">Pay in USA</v-list-item-title>
                        <v-list-item-subtitle>Tuition, Application &amp; SEVIS Fees</v-list-item-subtitle>
                     </v-list-item-content>
                     <v-list-item-action>
                        <v-btn icon>
                           <v-icon color="primary">mdi-arrow-right</v-icon>
                        </v-btn>
                     </v-list-item-action>
                  </v-list-item>
               </div>
               <div class="card elevation-4 rounded-lg">
                  <v-list-item class="px-5 py-3" link>
                     <v-list-item-avatar>
                        <v-img :src="require('../../assets/icons/canada.png')" lazy-src=""></v-img>
                     </v-list-item-avatar>
                     <v-list-item-content>
                        <v-list-item-title class="primary--text font-weight-medium">Pay in Canada</v-list-item-title>
                        <v-list-item-subtitle>Tuition, Application &amp; SEVIS Fees</v-list-item-subtitle>
                     </v-list-item-content>
                     <v-list-item-action>
                        <v-btn icon>
                           <v-icon color="primary">mdi-arrow-right</v-icon>
                        </v-btn>
                     </v-list-item-action>
                  </v-list-item>
               </div>
            </div>
        </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {  },
   data() {
      return {
         fundwallet: false,
         transfermoney: false,
         form: {
            method: 'wallet',
            destination: 'foreign'
         },
         loading: false,
         rules: {
            required: value => !!value || 'This field is required'
         },
         showpaybills: false,
         methods: [
            { text: 'Fund from Wallet', value: 'wallet' },
            { text: 'Fund from Card', value: 'card' },
            { text: 'Fund with Mpesa', value: 'mpesa' }
         ],
         currencies: [
            { text: 'NGN', value: 'NGN' },
            { text: 'GHS', value: 'GHS' },
            { text: 'KES', value: 'KES' },
            { text: 'UGX', value: 'UGX' },
            { text: 'ZAR', value: 'ZAR' },
            { text: 'TZS', value: 'TZS' }
         ],
         destination: [
            { text: 'Foreign Account', value: 'foreign' },
            { text: 'Wallet', value: 'wallet' }
         ],
         countries: [
            { text: 'Nigeria', value: 'NG' },
            { text: 'Ghana', value: 'GH' },
            { text: 'Kenya', value: 'KE' },
            { text: 'Uganda', value: 'UG' },
            { text: 'South Africa', value: 'ZA' },
            { text: 'Tanzania', value: 'TZ' }
         ],
         currency: {},
         banks: []
         
      }
   },

   watch: {
      
   },

   computed: {
      ...mapGetters('auth', ['user']),
   },
   
   mounted() {
     
   },

   methods: {
      ...mapActions('remittance', ['intFund']),
      ...mapActions('transfer', ['getallintbanks']),

      convert_currency() {
         let result_fund = this.form.currency_ngn / process.env.VUE_APP_ENV_NGN_USD
         let result_transfer = this.form.amount / process.env.VUE_APP_ENV_NGN_USD
         let val_fund = (result_fund / 1).toFixed(2).replace(',', '.')
         let val_transfer = (result_transfer / 1).toFixed(2).replace(',', '.')
         let filtered_fund = val_fund.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         let filtered_transfer = val_transfer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         this.form.currency_usd = filtered_fund
         this.form.currency = filtered_transfer
      }
      ,
      async fund() {
         this.loading = true
         this.form.email = this.user.email
         try {
            let response = await this.intFund(this.form)
            this.loading = false
            this.$toast.success(response.message)
            this.fundwallet = false
            this.$refs.fund.reset()
         } catch(error) {
            this.loading = false
            this.$toast.error(error.response.message)
         }
      },

      async getbanks() {
         const data = {
            country: this.form.country
         }
         try {
            let response = await this.getallintbanks(data)
            this.banks = response.data
         } catch(error) {

         }
      },

      async transfer() {
         this.loading = true
      }
   }
}
</script>

<style>
.flag{
   position: absolute;
    top: 5px;
    right: 50px;
}
.tr--icon {
   position: absolute;
   left: 46%;
   top: 52%;
   z-index: 1;
}
.tr--icon-2 {
   position: absolute;
   left: 46%;
   top: 15.8%;
   z-index: 1;
}
.tr--country .v-input__control .v-input__slot .v-input__append-inner {
   padding-right: 10px;
}
</style>