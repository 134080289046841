<template>
   <div>
      <v-card class="pa-1 rounded-lg text-center" outlined @click="requestCard()">
         <div class="grey lighten-5 pa-14">
            <div class="pa-3"><v-icon dark color="primary">mdi-plus</v-icon></div>
            <div class="text-subtitle-1 font-weight-bold primary--text">Get new card</div>
         </div>
      </v-card>
       <v-dialog v-model="dialog" width="550">
      <v-card class="form-colored-bg pa-8 px-16">
        <div class="text-center">
          <h4>Order a Physical Card</h4>
        </div>
        <v-form class="mt-10" lazy-validation ref="form">
          <p class="label">Card Name</p>
          <v-text-field
            outlined
            placeholder="Enter full name"
            v-model="form.name"
            required
            :rules="[(v) => !!v || 'Full name is required']"
          ></v-text-field>

          <p class="label">Phone Number</p>
          <v-text-field
            outlined
            placeholder="Phone Number"
            v-model="form.phone_number"
            required
            :rules="[(v) => !!v || 'Phone  is required']"
          ></v-text-field>

          <p class="label">Street Address</p>
          <v-text-field
            outlined
            placeholder="Street address"
            v-model="form.address"
            required
            :rules="[(v) => !!v || 'Address is required']"
          ></v-text-field>

          <p class="label">Select State</p>
          <v-select
            outlined
            @change="getlga(form.state)"
            item-text="name"
            item-value="name"
            placeholder="State"
            v-model="form.state"
            :items="states"
            required
            :rules="[(v) => !!v || 'State is required']"
          ></v-select>

          <p class="label">Select LGA</p>
          <v-select
            outlined
            placeholder="LGA"
            v-model="form.lga"
            item-text="name"
            item-value="name"
            :items="lga"
            required
            :rules="[(v) => !!v || 'LGA is required']"
          ></v-select>

          <v-btn
            class="primary"
            :loading="loading"
            @click="$refs.form.validate() ? orderCard() : null"
            block
            x-large
            >Order Card</v-btn
          >
          <div class="text-center">
            <small class="footnote"
              >You will be charged ₦3,000.00 for a card and you'll be required
              to cover the cost of delivery to any location outside
              Abuja.</small
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
   </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
        form: {},
        dialog: false,
        states: [],
        lga: [],
        loading: false
    }
  },
  
  computed: {
    ...mapGetters('auth', ['user']),
  },
  
  methods: {
    ...mapActions('remittance', ['ordercard']),

    async requestCard() {
      const data = {
        user_id: this.user.id,
        expiry_date: '2021-03-10'
      }

      try {
        let response = await this.ordercard(data)
        this.$toast.success(response.message)
      } catch(error) {
        this.$toast.error(error.response.data.message)
      }
    }
  }
}
</script>